import { FormControl, FormGroup, ValidationErrors } from '@angular/forms';
import { BookForm, RateConAnalysis } from './types';
import { HttpContextToken } from '@angular/common/http';
import { BookedLoad, RateConFile, TransformedTripData } from '../../features/dispatcher/features/booked-loads/components/add-edit-load/models/booked-loads.types';

export const IS_PUBLIC_API = new HttpContextToken<boolean>(() => false)

export function getControl(form: FormGroup, controlName: string) {
  return form.get(controlName) as FormControl;
}

export function translateErrorIntoMessages(error: ValidationErrors | null) {
  if (error === null) return '';
  if ('required' in error) {
    return 'This field is required';
  } else if ('email' in error) {
    return 'Please enter a valid email address';
  }
  return 'Something went wrong';
}

export function isChildInvalid(formGroup: FormGroup, formControlName: string) {
  if (
    formGroup.get(formControlName)?.invalid &&
    (formGroup.get(formControlName)?.touched ||
      formGroup.get(formControlName)?.dirty)
  ) return 'ng-invalid ng-touched';
  return '';
}

export function formatSize(bytes: number | undefined) {
  const kb = 1024;
  const dm = 3;
  const sizes = ['B', 'KB', 'MB'];
  if (!bytes) {
      return `0 ${sizes[0]}`;
  }

  const index = Math.floor(Math.log(bytes) / Math.log(kb));
  const formattedSize = parseFloat((bytes / Math.pow(kb, index)).toFixed(dm));

  return `${formattedSize} ${sizes[index]}`;
}




export function formatDatePattern(inputDate: string): string {
  const date = new Date(inputDate);
  const options: Intl.DateTimeFormatOptions = {
    weekday: 'short',
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    timeZoneName: 'short'
  };
  
  return date.toLocaleDateString('en-US', options);
}

export function getTimePart(inputDate: string): string {
  const date = new Date(inputDate);
  const options: Intl.DateTimeFormatOptions = {
    hour: '2-digit',
    minute: '2-digit',
    hour12: false
  };

  return date.toLocaleTimeString('en-US', options);
}

export function convertAnalysisToBookForm(analysis: RateConAnalysis): BookForm {
  const pickUps = analysis.pick_ups;
  const dropOffs = analysis.drop_offs;
  return {
    loadInfo: {
      loadNumber: analysis.load_number,
      loadType: analysis.load_type,
      commodityDescription: analysis.commodity_material_description,
      weight: analysis.quantity_weight,
      price: analysis.price_quote,
      specialRequirements: analysis.special_requirements,
      pickUps: pickUps.map((pickUp) => ({ pickUpLocation: pickUp.pick_up_location, pickUpDate: getCorrectDateFormat(pickUp.pick_up_date), pickUpTime: getCorrectTimeFormat(pickUp.pick_up_time)})),
      dropOffs: dropOffs.map((dropOff) => ({ dropOffLocation: dropOff.drop_off_location, dropOffDate: getCorrectDateFormat(dropOff.drop_off_date), dropOffTime: getCorrectTimeFormat(dropOff.drop_off_time)})),
    },
    brokerInfo: {
      brokerName: analysis.broker_name,
      brokerEmail: analysis.broker_email,
      brokerNumber: analysis.broker_phone_number,
      brokerAddress: analysis.broker_address,
    }
  }
}

export function convertBookedLoadToBookForm(data: BookedLoad): TransformedTripData {

  // Load information
  const loadInfo = {
    loadNumber: data.loadNumber,
    loadType: data.loadType.id,
    commodityDescription: data.commodity,
    weight: data.quantity,
    price: data.price,
    specialRequirements: data.specialRequirements,
    pickUps: data.pickups.map((pickup) => ({
      pickUpLocation: pickup.location,
      pickUpDate: pickup.date,
      pickUpTime: pickup.time,
    })),
    dropOffs: data.dropoffs.map((dropoff) => ({
      dropOffLocation: dropoff.location,
      dropOffDate: dropoff.date,
      dropOffTime: dropoff.time,
    })),
  };

  // Broker information
  const brokerInfo = {
    brokerName: data.brokerName,
    brokerEmail: data.brokerEmail,
    brokerNumber: data.phoneNumber, // Assuming 'phoneNumber' is broker number
    brokerAddress: data.address,
  };

  // Driver details
  const driverDetails = {
    driverNames: data.drivers.map((driver) => driver.id || ''),
    truckNumbers: data.trucks.map((truck) => truck.id || ''),
    trailerNumbers: data.trailers.map((trailer) => trailer.id || ''),
  };
  return { rateCon: { rateConFile: { ...data.documents[0] } }, loadInfo, brokerInfo, driverDetails };
}



export function getInitials(firstName: string, lastName: string): string {
  const firstInitial = firstName.charAt(0).toUpperCase();
  const lastInitial = lastName.charAt(0).toUpperCase();
  return `${firstInitial}${lastInitial}`;
}

export function isRateConFile(file: File | RateConFile): file is RateConFile {
  return (file as RateConFile).url !== undefined; 
}

export function buildQueryParams(params: Record<string, string | number>) {
  const newParams: Record<string, string | number> = {};
  for (const key in params) {
    if (params[key]) {
      newParams[key] = params[key];
    }
  }
  return newParams
}

export function getCorrectTimeFormat(time: string) {
  if (time.length > 5 ) {
    return time.slice(0, 2) + ':' + time.slice(3, 5)
  }
  if (time[2] !== ':') {
    return time.slice(0, 2) + ':' + time.slice(2, 4);
  }
  return time;
}

export function getCorrectDateFormat(date: string) {
  if (isNaN(Date.parse(date))) {
    return '';
  }
  return date;
}